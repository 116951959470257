import logo from './asset/daniyalperfumelogo.PNG'
import './App.css';

function App() {
  return (
    <>
      <section className='comingsoon'>
        <div className='container'>
          <div className="App">
            <img className='img' height={500} width={500} src={logo} alt='logo_img'></img>

            <p className='text'><span style={{ color: "red" }}>We're</span> Coming Soon...</p>
          </div>

        </div>
      </section>
    </>
  );
}

export default App;
